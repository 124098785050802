import React from "react";
import { usePagination, DOTS } from "../../hooks/usePagination";
import "../../sass/component/pagination.scss";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  productLength,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    productLength,
  });

  try {
    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }
  } catch {
    return [];
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`pagination-container ${className ? className : ""}`}>
      {/* Left Arrow */}
      <li
        key={"left-arrow"}
        className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={"dots"} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        /* Numbers for Pagination */
        return (
          <li
            key={pageNumber}
            className={`pagination-item ${
              currentPage === pageNumber ? "selected" : ""
            }`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}

      {/* Right Arrow */}
      <li
        key={"right-arrow"}
        className={`pagination-item ${
          currentPage === lastPage ? "disabled" : ""
        }`}
        onClick={onNext}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Space") {
            onNext();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
