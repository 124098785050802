import React from "react";
import Layout from "../layout/Layout";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Pagination from "../components/common/Pagination";
import { pagination } from "../utils/common";
import defaultImage from "../../static/img/default-bc-product.png";
import Image from "../components/common/Image";
import { blogStaticImg } from "../ApiEndpoints";
import { sliceAfterMatch } from "../utils/common";

const BlogsPageTemplate = ({ location, blogs, pageContext }) => {
  const { currentPage, totalPages } = pageContext;
  const limitWords = (text, limit) => {
    const words = text.trim().split(" ");
    const limitedWords = words.slice(0, limit);
    return limitedWords.join(" ");
  };

  return (
    <div className="page-wrapper">
      <div className="container">
        <Breadcrumbs location={location} />
      </div>
      <div className="page-heading-section">
        <div className="container">
          <h2 className="page-title">{"Blogs"}</h2>
        </div>
      </div>

      <div className="blog-list-section">
        <div className="container-small">
          {blogs.findIndex((blog) => blog?.index === 0) !== -1 &&
            blogs
              .filter((blog) => blog && blog !== undefined && blog?.index === 0)
              .map((blog) => (
                <div
                  className="featured-blog-item flex vertical-middle flex-wrap"
                  key={blog?.id}
                >
                  <div className="blog-image-block">
                    <Link to={blog?.url}>
                      {blog?.data?.image_url.includes("/blogs/images") ? (
                        <Image
                          src={`${
                            process.env.GATSBY_IMAGE_CDN_BASEURL
                          }${blogStaticImg}${sliceAfterMatch(
                            blog?.data?.image_url,
                            "/blogs/images"
                          )}`}
                          alt={blog?.name}
                          title={blog?.name}
                        />
                      ) : (
                        <Image
                          src={defaultImage}
                          alt={blog?.name}
                          title={blog?.name}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="blog-detail-block">
                    <h2 className="font-bold h1">
                      <Link to={blog?.url}>{blog?.name}</Link>
                    </h2>
                    <p
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: limitWords(blog?.data?.short_desc, 163),
                      }}
                    ></p>
                    <Link className="read-more-link" to={blog?.url}>
                      Read More
                    </Link>
                  </div>
                </div>
              ))}

          <div className="blog-list flex flex-wrap vertical-top">
            {blogs.map(
              (blog) =>
                blog &&
                blog !== undefined &&
                blog?.index !== 0 && (
                  <div className="blog-list-item">
                    <div className="blog-image-block">
                      <Link to={blog?.url}>
                        {blog?.data?.image_url.includes("/blogs/images") ? (
                          <Image
                            src={`${
                              process.env.GATSBY_IMAGE_CDN_BASEURL
                            }${blogStaticImg}${sliceAfterMatch(
                              blog?.data?.image_url,
                              "/blogs/images"
                            )}`}
                            alt={blog?.name}
                            title={blog?.name}
                          />
                        ) : (
                          <Image
                            src={defaultImage}
                            alt={blog?.name}
                            title={blog?.name}
                          />
                        )}
                      </Link>
                    </div>
                    <div className="blog-detail-block">
                      <h2 className="font-bold h3">
                        <Link to={blog?.url}>{blog?.name}</Link>
                      </h2>
                      <p
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: limitWords(blog?.data?.short_desc, 163),
                        }}
                      ></p>
                      <Link className="read-more-link" to={blog?.url}>
                        Read More
                      </Link>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>

        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalPages}
          onPageChange={(page) => pagination(page, "blogs")}
        />
      </div>
    </div>
  );
};

BlogsPageTemplate.propTypes = {
  blogs: PropTypes.array,
};

function BlogsPage({ data, location, pageContext }) {
  const allBlogs = data.allDynamicBlogs.edges.map((item) => {
    const { id, index, url, description, name, data } = item.node;

    if (url) {
      return {
        id,
        index,
        url,
        name,
        description,
        data,
      };
    } else {
      return null;
    }
  });
  return (
    <BlogsPageTemplate
      location={location}
      pageContext={pageContext}
      blogs={allBlogs}
    />
  );
}

BlogsPage.propTypes = {
  data: PropTypes.shape({
    allDynamicBlogs: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.object,
};

BlogsPage.Layout = Layout;
export default BlogsPage;

export const blogsPageQuery = graphql`
  query getAllBlogs($skip: Int!, $limit: Int!) {
    allDynamicBlogs(limit: $limit, skip: $skip, filter: { id: { ne: "111" } }) {
      edges {
        node {
          id
          index
          name
          url
          description
          data {
            image_url
            short_desc
          }
        }
      }
    }
  }
`;
